import React from "react"
import PropTypes from "prop-types"
import CssBaseline from "@material-ui/core/CssBaseline"
import Container from "@material-ui/core/Container"
import Header from "../components/page/Header"
import { ThemeProvider } from "@material-ui/styles"
import { theme } from "../var/theme"
import NoSsr from "@material-ui/core/NoSsr"
import { Card, CardContent, createStyles, makeStyles, useScrollTrigger } from "@material-ui/core"
import Zoom from "@material-ui/core/Zoom"
import Fab from "@material-ui/core/Fab"
import { KeyboardArrowUpRounded as KeyboardArrowUpIcon } from "@material-ui/icons"
import { scrollTo } from "../var/util"
import Footer from "../components/partials/Footer"
import Meta from "../components/Meta"
import Typography from "@material-ui/core/Typography"
import Support from "../components/partials/Support"
import DoneIcon from "@material-ui/icons/DoneRounded"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import ButtonBase from "@material-ui/core/ButtonBase"
import Link from "@material-ui/core/Link"
import Avatar from "@material-ui/core/Avatar"
import DownloadSimple from "../components/partials/DownloadSimple"

const useStyles = makeStyles((t) => createStyles({
  main: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
  },
  scroll: {
    position: "fixed",
    bottom: t.spacing(2),
    right: t.spacing(2),
  },
  toolbar: {
    ...t.mixins.toolbar,
    background: theme.custom.fancyBackground,
  },
  content: {
    flex: 1,
  },
  card: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    textAlign: "center",
  },
  featureList: {
    listStyleType: "none",
    marginLeft: 0,
    paddingLeft: "1em",
    textIndent: "-1em",
  },
  image: {
    maxWidth: "100%",
    width: 400,
    maxHeight: "100%",
    height: "auto",
    // borderRadius: 8,
  },
  dialogImage: {
    // maxWidth: "100%",
    // maxHeight: "100%",
    borderRadius: 8,
  },
  imageHolder: {
    // cursor: "pointer",
    marginBottom: theme.spacing(2),
    maxWidth: "100%",
    maxHeight: "100%",
  },

}))

function ScrollTop(props) {
  const { children, window } = props
  const classes = useStyles()
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = event => {
    scrollTo("#top-anchor")
  }
  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.scroll}>
        {children}
      </div>
    </Zoom>
  )
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

const IndexPage = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query {
     newReminder: file(relativePath: {eq: "how-to/new_reminder.jpg"}) {
       childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 400, quality: 100) {
         ...GatsbyImageSharpFixed_noBase64
        }
        full: fixed(width: 800, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    newReminderPopup: file(relativePath: {eq: "how-to/new_reminder_popup.jpg"}) {
       childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 429, quality: 100) {
         ...GatsbyImageSharpFixed_noBase64
        }
        full: fixed(width: 429, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    actions: file(relativePath: {eq: "how-to/actions.jpg"}) {
       childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 400, quality: 100) {
         ...GatsbyImageSharpFixed_noBase64
        }
        full: fixed(width: 800, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    topSites: file(relativePath: {eq: "how-to/top_sites.jpg"}) {
       childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 400, quality: 100) {
         ...GatsbyImageSharpFixed_noBase64
        }
        full: fixed(width: 800, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    reddit: file(relativePath: {eq: "how-to/reddit.jpg"}) {
       childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 400, quality: 100) {
         ...GatsbyImageSharpFixed_noBase64
        }
        full: fixed(width: 800, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    dark: file(relativePath: {eq: "mr_dark.png"}) {
       childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
        full: fixed(width: 800, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`)
  const [open, setOpen] = React.useState(false)
  const [dialogImage, setDialogImage] = React.useState()
  const [dialogImageText, setDialogImageText] = React.useState("")

  const handleClose = () => {
    setOpen(false)
  }
  const showDialog = (img, text) => {
    setDialogImage(img)
    setDialogImageText(text)
    setOpen(true)
  }
  return (
    <React.Fragment>
      <Meta title={"Help"} />
      <NoSsr>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <main className={classes.main}>
            <Header title="Help"/>
            <section id={"content"} className={classes.content}>
              <div className={classes.toolbar}/>
              <Container maxWidth={"md"}>
                {/*<PageTitle title={"Help"}/>*/}
                <Card className={classes.card}>
                  <CardContent>
                    <Typography align={"center"} variant={"h6"}>How to create reminder?</Typography>
                    <Typography align={"left"} variant={"body1"}>
                      There are two ways to add new reminder:
                    </Typography>
                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      Open new tab, enter your reminder into "Remember to..." field and press the save button. You can
                      customize your reminder by adding more information into "More to add?" field or choose an icon by
                      clicking on the <DoneIcon fontSize={"small"}/> icon.
                    </Typography>
                    <Container>
                      <ButtonBase
                        focusRipple
                        className={classes.imageHolder}
                        onClick={() => showDialog(data.newReminder.childImageSharp.full, "New reminder")}
                      >
                        <Avatar src={data.newReminder.childImageSharp.fixed.src}
                                variant={"rounded"}
                                className={classes.image}/>
                      </ButtonBase>
                    </Container>
                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      Or add directly from the popup in the browser toolbar by clicking on the "Memo Reminders" button.
                    </Typography>
                    <ButtonBase
                      focusRipple
                      className={classes.imageHolder}
                      onClick={() => showDialog(data.newReminderPopup.childImageSharp.full, "New reminder from popup")}
                    >
                      <Avatar src={data.newReminderPopup.childImageSharp.fixed.src}
                              variant={"rounded"}
                              className={classes.image}/>
                    </ButtonBase>
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography align={"center"} variant={"h6"}>What are actions?</Typography>
                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      Actions are here to help you with the task by customizing the action when you click on the
                      notification action button.
                    </Typography>
                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      You can set the action to open website, navigate on Google Maps, prepare email...
                    </Typography>
                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      On your mobile phone you can do many useful things as dial phone number, prepare SMS or email,
                      navigate to your destination or even launch application like the Camera app for #accidentalselfie.
                    </Typography>
                    <ButtonBase
                      focusRipple
                      className={classes.imageHolder}
                      onClick={() => showDialog(data.actions.childImageSharp.full, "Actions")}
                    >
                      <Avatar src={data.actions.childImageSharp.fixed.src}
                              variant={"rounded"}
                              className={classes.image}/>
                    </ButtonBase>
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography align={"center"} variant={"h6"}>Widgets</Typography>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography align={"left"} variant={"body1"} gutterBottom>
                          To make your new tab page more useful, you can can add widgets to new tab page.
                        </Typography>
                        <Typography align={"left"} variant={"body1"} component={"div"} gutterBottom>
                          Currently there are two widgets:
                          <li>Top Sites</li>
                          <li>Reddit</li>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography align={"left"} variant={"body1"} gutterBottom>
                          <b>Top Sites</b> widget allows you to add your top sites for quick access
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ButtonBase
                          focusRipple
                          className={classes.imageHolder}
                          onClick={() => showDialog(data.topSites.childImageSharp.full, "Top Sites widget")}
                        >
                          <Avatar src={data.topSites.childImageSharp.fixed.src}
                                  variant={"rounded"}
                                  className={classes.image}/>
                        </ButtonBase>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ButtonBase
                          focusRipple
                          className={classes.imageHolder}
                          onClick={() => showDialog(data.reddit.childImageSharp.full, "Reddit widget")}
                        >
                          <Avatar src={data.reddit.childImageSharp.fixed.src}
                                  variant={"rounded"}
                                  className={classes.image}/>
                        </ButtonBase>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography align={"left"} variant={"body1"} gutterBottom>
                          <b>Reddit</b> widget lets you see the latest/new/hot posts from your favorite subreddit.
                        </Typography>

                        <Typography align={"left"} variant={"body1"}>
                          More widgets will be added in future.
                          If you have suggestions, please send an email to <Link color={"inherit"}
                                                                                 href={"mailto:hello@memoreminders.com"}
                                                                                 underline={"always"}>hello@memoreminders.com</Link>.

                        </Typography>

                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography align={"center"} variant={"h6"}>Get it now</Typography>
                    <DownloadSimple/>
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent>
                    <Support/>
                  </CardContent>
                </Card>
              </Container>
            </section>
            <Footer excludeSupport={true}/>
          </main>
          <ScrollTop>
            <Fab color="secondary" size="small" aria-label="scroll back to top">
              <KeyboardArrowUpIcon/>
            </Fab>
          </ScrollTop>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"xl"}
          >
            <DialogTitle id="alert-dialog-title">{dialogImageText}</DialogTitle>
            <DialogContent>
              {dialogImage &&
              <Img fixed={dialogImage}
                   imgStyle={{
                     objectFit: "contain",
                   }}

                   loading={"eager"} title={dialogImageText}
                   className={classes.dialogImage}
              />
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </NoSsr>
    </React.Fragment>
  )
}

export default IndexPage
